<template>
  <div>
    <b-card  v-for="(game, index) in games" :key="index" :class="is_detail_network ? 'border-box-analytics' : ''">
      <b-card-header class="pb-0">
        <b-card-title>{{$t('twitch.categories')}}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="pt-1">
              <b-col sm="6" lg="3">
                <b-card
                class="card-profile pt-4"
              >
                <div class="profile-image-wrapper">
                  <div class="profile-image p-0">
                    <b-avatar
                      size="114"
                      variant="light"
                      :src="games_image[index]"
                    />
                  </div>
                </div>
                <h5>{{game.basic.title}}</h5>
              </b-card>
              </b-col>
              <b-col sm="6" lg="3">
                <statistic-card-with-line-chart
                  icon="MessageSquareIcon"
                  :statistic="$t('twitch.nStreams')"
                  :statistic-title="$t('twitch.duringlast')"
                  :chart-data="[{name:  $t('twitch.streams'), data: utils.getSeries(utils.convertData(game.metrics.streams_count.performance),'value')}]"
                  :color="'successs'"
                  :chartOptions="chartOptions"
                  :heightChart="130"
                />
              </b-col>
              <b-col sm="6" lg="3">
                <statistic-card-with-line-chart
                  icon="MessageSquareIcon"
                  :statistic="$t('youtube.averageViews')"
                  :statistic-title="$t('twitch.duringlast')"
                  :chart-data="[{name:  $t('twitch.streams'), data: utils.getSeries(utils.convertData(game.metrics.views_avg.performance),'value')}]"
                  :color="'successs'"
                  :chartOptions="chartOptions"
                  :heightChart="130"
                />
              </b-col>
              <b-col sm="6" lg="3">
                <statistic-card-with-line-chart
                  icon="MessageSquareIcon"
                  :statistic="$t('twitch.hoursStream')"
                  :statistic-title="$t('twitch.duringlast')"
                  :chart-data="[{name: $t('twitch.streams'), data: utils.getSeries(utils.convertData(game.metrics.hours_streamed.performance),'value')}]"
                  :color="'successs'"
                  :chartOptions="chartOptions"
                  :heightChart="130"
                />
              </b-col>
            </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader,  BCardBody, BRow, BCol, BAvatar, BCardTitle
} from 'bootstrap-vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import utils from '@/libs/utils'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    StatisticCardWithLineChart
  },
  props: {
    twitchData: {
      type: Object,
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 30,
            right: 30,
            bottom: 30
          },
        },
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['30d', '90d', '180d', '365d'],
          show: true,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            show: true,
            offsetY: -5,

          }
        },
        colors: ['#A9A2F6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#A9A2F6'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          x: { show: true },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show:true,
          position: 'top'
        }
      },
      utils,
      games_image: [],
      games: []
    }
  },
  mounted() {
    this.games = this.twitchData.games.data.slice(0, 5);
    const self = this;
    this.games.forEach((game) => {
      utils.urlImagetoBase64(game.basic.avatar_url, function (result) {
        self.games_image.push(result)
      });
    })
  }
}
</script>
